import React, { useRef, useEffect } from "react";

function useClickOutside(ref,onClickOutside) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside()
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const ClickOutside = ({children, onClickOutside=()=>{}, ...rest}) => {
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, onClickOutside);

  return <div ref={wrapperRef} {...rest}>{children}</div>;
}

export default ClickOutside;
