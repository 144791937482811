import { Route, Routes, useLocation } from 'react-router-dom';
import HP from './routes/HP';
import Onas from './routes/Onas';
import Temata from './routes/Temata';
import Detail from './routes/Detail';
import Layout from './routes/Layout';
import { useEffect, useState } from 'react';
import Projekty from './routes/Projekty';
import { useLenis } from '@studio-freight/react-lenis';

const Router = () => {
    const location = useLocation();
    const [language, setLanguage] = useState(null);
    const lenis = useLenis();

    useEffect(()=>{
        if (["/en","about","project","topics","article"].some(path=>location.pathname.includes(path))) {
            setLanguage('en')
        } else {
            setLanguage('cs')
        }
        lenis?.scrollTo(0)
    },[location]) 

    return (
        <Layout language={language} >
            <Routes>
              <Route exact path="/" element={<HP />} /> 
              <Route exact path="/:translation" element={<HP />} /> 

              <Route exact path="/o-nas" element={<Onas />} />  
              <Route exact path="/about" element={<Onas />} />  

              <Route exact path="/projekty" element={<Projekty />} />  
              <Route exact path="/projects" element={<Projekty />} />  

              <Route exact path="/projekt/:id" element={<Detail type="projects" />} />  
              <Route exact path="/project/:id" element={<Detail type="projects" />} />  

              <Route exact path="/temata" element={<Temata />} />  
              {/* <Route exact path="/topics" element={<Temata />} /> */}

              <Route exact path="/temata/:tema" element={<Temata />} />  
              {/* <Route exact path="/topics/:tema" element={<Temata />} />   */}

              <Route exact path="/clanek/:id" element={<Detail type="articles" />} />  
              {/* <Route exact path="/article/:id" element={<Detail type="articles" />} />   */}
            </Routes>
        </Layout>
    )
}

export default Router;