import axios from "axios";
import parse from 'html-react-parser';
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { APIURL, STORAGEURL, toURL } from "..";
import { AppContext } from "./Layout";
import Loading from "../components/UI/Loading";
import { ArrowLeft } from "@phosphor-icons/react";
import Article from "../components/Article";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import FsLightbox from "fslightbox-react";


const Detail = ({type='articles'}) => {
    const navigate = useNavigate();   
    const {language} = useContext(AppContext); 
    const {id} = useParams(); 
    const [data, setData] = useState();    
    const [slide, setSlide] = useState(null);
    const [lightbox, setLightbox] = useState(false);
    let slidesNum = window.innerWidth>800?2:1;

    const [idInt, setIdInt] = useState();

    const links = {
        projects: {cs:'/projekty', en:'/projects'},
        articles: {cs:'/temata', en:'/topics'},
        projects_this: {cs:'/projekt/', en:'/project/'},
        articles_this: {cs:'/clanek/', en:'/article/'},
    }
    const getDetail = async () => {
        setData(null)
        await axios.post(APIURL+'/article', {id_group:Number(idInt), language}).then(async response =>{
            let url = toURL(response.data.body.article.name);
            navigate(links[type+"_this"][language]+idInt+'-'+url, {replace:true});
            if (response.data.body.article.gallery) {
                response.data.body.article.gallery = response.data.body.article.gallery.split("~").filter(Boolean).map(p=>STORAGEURL+p);
            }
            if (response.data.body.article.files) {
                response.data.body.article.files = response.data.body.article.files.split("~").filter(Boolean).map(p=>STORAGEURL+p);
            }
            setData(response.data.body.article);
        })
    } 

    useEffect(()=>{
        if (id) setIdInt(id.split('-')[0]);
    },[id])

    useEffect(()=>{
        if (idInt&&language) getDetail();
    },[idInt,language])

    return (
        <main className="w-full flex flex-col gap-12 items-center maxSection px-2 pb-6 lg:px-6 pt-20 tablet:pt-10"> 
            {data?<div className="max-w-full flex flex-col gap-2">
                <Link className="py-2 group uppercase text-sm w-max" to={data.articleType_id=='2'?(language=="cs"?'/projekty':'/projects'):(language=="cs"?'/temata':'/topics')} >
                        <div className=' flex items-center cursor-pointer mr-auto'><ArrowLeft/>Zpět</div>
                        <div className="bg-white h-[1px] w-0 group-hover:w-full transition-all duration-500"></div>
                </Link>
                <article className="articleBody bg-white rounded-lg m-auto w-full min-h-[600px] p-1 py-4 lg:p-4 flex flex-col gap-2 lg:gap-4 items-center transition-all">
                        <span className="uppercase text-font-light-subheader text-base lg:text-lg">{data.articleType_id=='2'?(language=="cs"?"Projekty":"Projects"):(language=="cs"?"Článek":"Article")}</span>
                        <h1 className="text-font-light-header text-2xl lg:text-5xl text-center">{data?.name}</h1>
                        {data?.image&&<img className="rounded-lg" src={data?.image} alt="Ilustrační obrázek" />}
                        <div className="max-w-full flex justify-center text-font-light-primary">
                            <div className="w-[90%] lg:w-[70%] my-6">
                            {data?<>
                                <div>{parse(data?.perex||"")} <br/></div>
                                <div>{parse(data?.content_wysiwygbig||"")}</div>
                                {data.web&&<a className="button !m-auto !mt-8" href={data.web}>{language=="cs"?'Přejit na web projektu':"Go to project's website"}</a>}
                                {data?.video_url && <div className="my-4"><ReactPlayer className="mx-auto max-w-full" url={parse(data?.video_url)} /></div>
                                } 
                                {data.gallery&&<div className="gallery">
                                    <Swiper
                                        modules={[Navigation]}
                                        navigation={true}
                                        slidesPerView={slidesNum}
                                        spaceBetween={32}
                                        className="gallerySwiper"
                                    >
                                        {data.gallery.map(d => (<SwiperSlide key={d} onClick={()=>{setSlide(d);setLightbox(!lightbox)}} >
                                            {(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(d)?
                                                <img src={d} alt="obrázek galerie" className="aspect-[3/2] object-cover h-72" />
                                                : <div className="video"><ReactPlayer url={d} /></div>}
                                            </SwiperSlide>))}
                                    </Swiper>
                                    <FsLightbox
                                        toggler={lightbox}
                                        sources={data.gallery}
                                        source={slide}
                                    />
                                </div>}
                                {data?.files && (
                                    <div className="my-4 flex flex-col gap-2">
                                        <b>{data.files_name}</b>
                                        {data.files.map(f=>(
                                            <a href={f} key={f} target="_blank" className="text-font-light-header underline">{f.split("__")[1]||"Odkaz"}</a>
                                        ))}
                                    </div>
                                )} 
                            </>:<Loading />}
                            </div>
                        </div> 
                </article>
            </div>:<Loading />}
            {data?.recommended_articles&&<section className="maxSection my-4 flex flex-col items-center gap-4 p-1 py-4">   
                <h2 className='uppercase text-center text-2xl lg:text-4xl'>{data.recommended_title}</h2>
                <div className='flex flex-wrap justify-center gap-4'> 
                    {data.recommended_articles?.filter(a=>(a.id_group!=id.split('-')[0])&&a.language_id==1).slice(0,3).map(a =>(<Article key={a.id} data={a} className="nemobil:max-w-[30%]" />))||<Loading classC={"min-h-full"} />}   
                </div>
                <button className='button' onClick={()=>navigate(links[type][language])}>Zobrazit další</button>
            </section>}
        </main>
    )
}

export default Detail;