import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Eye, EyeSlash, UploadSimple, X, ArrowClockwise } from '@phosphor-icons/react' 

const Input = ({ label, name, type = 'text', required, pattern, placeholder, className = '', single, invisible,onEnter, onButtonClick, debounce, onChange=()=>{}, ...rest }) => {
  const form = useFormContext()||{};
  const { register, getValues, formState } = form;

  const debounceTimer = useRef(null); 

  const handleOnChange = (e) => {
    if (!e) return
    let val = e.target.value;
    if (single || !form.onChange) {
      if (debounce) {
        if (debounceTimer.current) clearTimeout(debounceTimer.current);
        debounceTimer.current = setTimeout(() => {
          onChange(val);
        }, debounce === true ? 1000 : debounce);
      } else {
        onChange(val);
      }
    } else {
      form.onChange(e); 
      onChange(val); 
    }
  };
  useEffect(() => {
    return () => {
      if (debounceTimer.current) clearTimeout(debounceTimer.current);
    };
  }, []);

  // validation
  const [autopattern, setAutopattern] = useState();
  const [validationRules, setValidationRules] = useState();
  useEffect(()=>{
    if (!pattern) {
      if (type === 'email') setAutopattern(/[^@\s]+@[^@\s]+\.[^@\s]+/);
      if (type === 'tel') setAutopattern(/^[+-]?[\s\d]+$/);
      if (type === 'number') setAutopattern(/^[0-9]*$/);
    }
    setValidationRules({
      required: required ? "Toto pole je povinné" : false,
      pattern: pattern || autopattern ? { value: pattern || autopattern, message: "Neplatný formát" } : undefined,
    })
  }, [type,required, pattern])
  // 

  // password
  const [showPass, setShowPass] = useState(type === 'password' ? 'password' : type);
  // 

  const handleKeyDown = (event) => {
    if (event.key==="Enter"&&onEnter) {
      event.preventDefault();
      onEnter(event.target.value);
    }
  }
  const handleButtonClick = () => {
    if (onButtonClick) {
      onButtonClick(getValues?getValues(name):document.getElementsByName(name)[0].value)
    }
  }
  return (
    <div className={'superinput ' + className} style={invisible?{display:'none'}:{}}>
      {label && <label htmlFor={name} className='block '><p>{label} {required>0&&<sup>*</sup>}</p></label>}
      <div className='superinput_box'>
        {type === 'textarea' ?
          <textarea
            name={name}
            id={name}
            placeholder={placeholder || label}
            className={formState?.errors[name] && 'err'}
            {...register(name, validationRules)}
            {...rest} ></textarea> :
          <input
            name={name}
            id={name}
            type={showPass ? showPass : type}
            placeholder={placeholder || label}
            {...(single ? {} : register?.(name, validationRules))}
            {...rest}
            className={formState?.errors[name] ? 'err':''}
            onChange={handleOnChange}
            onKeyDown={handleKeyDown}
            style={{height: '36px'}}
          />
        }
        {rest.button && <button type={single?'submit':'button'} className='reset' onClick={handleButtonClick}>{rest.button}</button>}
        {type === 'password' && showPass === 'password' && <Eye className='eye' onClick={() => setShowPass('text')} />}
        {type === 'password' && showPass === 'text' && <EyeSlash className='eye' onClick={() => setShowPass('password')} />}
      </div>
      {formState?.errors[name] && !single && <p className='err'>{formState?.errors[name]?.message}</p>}
    </div>
  );
};

export default Input;
