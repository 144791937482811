const CustomLink = ({href, color, children, target, ...rest}) => {
    return (
        <span className='w-max cursor-pointer' {...rest}>
            <a className={"group text-sm text-right " + "text-"+color} href={href} target={target} >
                {children}
                <div className={"h-[2px] w-0 group-hover:w-full transition-all duration-500 " + "bg-"+color} ></div>
            </a>
        </span>
    )
}
export default CustomLink;