import { CaretDown, CaretRight } from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";

const Collapsible = ({ children, icon, text, open = false, onCollapsibleChange, clickable }) => {
    const [isOpen, setIsOpen] = useState(open);
    const [maxHeight, setMaxHeight] = useState(0);
    const contentRef = useRef(null);
    const calculateHeight = () => {
        if (contentRef.current) {
            const contentHeight = isOpen ? contentRef.current.scrollHeight : 0;
            setMaxHeight(contentHeight);
            if (onCollapsibleChange) {
                setTimeout(() => {
                    onCollapsibleChange(contentHeight)
                }, 150);
            }
        }
    }
    useEffect(() => {
        calculateHeight();
      }, [isOpen]);
    
    return (
        <div className={"py-1 px-4 supercollapsible flex flex-col"}>
            <div className="flex justify-between items-center gap-4 pt-2">
                <div onClick={() => setIsOpen(!isOpen)} className="absolute w-full h-full left-0 top-0 opacity-0 cursor-pointer"></div>
                {icon&&<span className="w-10 h-8 flex items-center justify-center">{icon}</span>}
                <p onClick={()=>clickable?setIsOpen(!isOpen):null} className={clickable?'cursor-pointer':''}>{text}</p>
                <button onClick={() => setIsOpen(!isOpen)} className={`reset ml-auto text-xl transition-all ${isOpen?'rotate-180':'rotate-0'}` }><CaretDown /></button>
            </div>
            {children?.length>0&&<div ref={contentRef} style={{ maxHeight }} className={`flex flex-col gap-2 pl-4 overflow-hidden transition-[max-height] duration-150 ${isOpen ? "my-4" : ""}`}>
            {children && React.Children.map(children, (child) =>
                React.cloneElement(child, {
                    onCollapsibleChange: calculateHeight,
                })
            )}
            </div>}
        </div>
    )
}

export default Collapsible;
