import classNames from 'classnames';
import parse from 'html-react-parser';
import { useContext } from 'react';
import { AppContext } from '../routes/Layout';
import { STORAGEURL, toURL } from '..';
import { Link } from 'react-router-dom';

const Article = ({ size, data, className='' }) => {
    const {language, general} = useContext(AppContext);  
    const url = (language=='cs'?'/clanek/':'/article/')+data.id_group+'-'+toURL(data.name);
    return (
        <div className={classNames(`article bg-white rounded-4xl min-w-[200px] flex flex-col items-center gap-4 p-2`,
            {
                'nemobil:!flex-row': size === 'mini',
            }
        )+' '+className}>
            <Link to={url} className='self-stretch'>
                <img src={STORAGEURL+(data?.image ? data.image:general?.logo_header)} className={"bg-blues-300 rounded-4xl w-full h-full aspect-[3/2] "+(data.image?'object-cover ':'object-contain ')+(size === 'mini'&&'nemobil:!w-[192px]')}></img>
            </Link>
            <div className={classNames(`flex flex-col flex-grow gap-4 p-2 h-full w-full `,
                {
                    'nemobil:max-w-[50%]': size === 'mini',
                })}>
                <h4 className={'text-font-light-header '+(size==='mini'?'nemobil:text-base':'')}><Link to={url} >{data?.name||data?.article_name}</Link></h4>
                <p className={classNames(`text-base text-font-light-primary line-clamp-3 `,
                    {
                        'nemobil:hidden': size === 'mini',
                    })}>{parse(data.perex||"")}</p>
                <div className='flex justify-end mt-auto'>
                    <Link className="text-font-light-link px-4 group uppercase text-sm text-right " to={url} >
                        Číst více
                        <div className="bg-font-light-link h-[2px] w-0 group-hover:w-full transition-all duration-500"></div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Article 