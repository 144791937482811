import { useContext, useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AppContext } from "./Layout";
import Loading from "../components/UI/Loading";
import { APIURL, toURL } from "..";
import axios from "axios";
import Article from "../components/Article";
import Collapsible from "../components/UI/Collapsible";
import Checkbox from "../components/Form/Checkbox";
import ClickOutside from "../components/UI/useClickOutside";
import { X } from "@phosphor-icons/react";
import ridic from '../assets/svg/ridic.svg';
import Input from "../components/Form/Input";
import RadioInput from "../components/Form/RadioInput";

const Temata = () => {
    const {tema} = useParams();
    const location = useLocation();
    const {perexes,language,topics} = useContext(AppContext);
    const [active, setActive] = useState(null)
    const [articles, setArticles] = useState();
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filters, setFilters] = useState(null);
    const [temp, setTemp] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState();

    const timer = useRef();

    const fltrRef = useRef();

    const navigate = useNavigate();

    const getArticles = async () => {
        let category = tema?tema.split('-')[0]:null;
        const catArr = filters?.filters?.map(f=>Number(f.id))||[];
        if (category&&!catArr.length) catArr.push(Number(category));
        const orderAsc = filters?.order?filters.order:search?'ASC':undefined;
        await axios.post(APIURL+((search||orderAsc)?'/articles':'/article/filtration/advanced'), {type:'articles', language:language||'cs', orderAsc, limit:1000, page:1, category:catArr, search}).then(async response =>{
            setArticles(response.data.body.articles);  
        }) 
    } 

    const handleSearch = ({detail}) => setSearch(detail);

    const handleCheckbox = (e) => {
        let obj = {id:e.target.name.split("~")[1], display_name:e.target.name.split("~")[0]};
        setTemp(prev=>{
            let prevFilters = prev?.filters||[]; 
            return {order:prev?.order, filters:e.target.checked?[...prevFilters,obj]:prevFilters.filter(i=>i.id!=obj.id)}
        })
    }

    const handleOrder = (e) => {
        setTemp(prev=>({filters:prev.filters, order:e?.target?.value}))
    }

    const handleSearchParams = () => {
        // let param = topics.find(t=>t.id==tema?.split('-')[0])?.children.find(c=>toURL(c.display_name)==searchParams.get('t'));
        // if (param) {
        //     setFilters(()=>({order:null,filters:[{id:param.id,display_name:param.display_name}]}));
        // } else {
        //     setFilters(null);
        // }
        const param = searchParams.get("t"); 
        if (param) {
            const topic = topics
                .find((t) => t.id == tema?.split('-')[0])
                ?.children.find((c) => toURL(c.display_name) === param);

            if (topic) {
                setFilters(() => ({
                    order: null,
                    filters: [{ id: topic.id, display_name: topic.display_name }]
                }));
            }
            const updatedParams = new URLSearchParams(searchParams);
            updatedParams.delete("t");
            setSearchParams(updatedParams);
        }

    }

    useEffect(()=>{
        if (perexes) {
            let item;
            if (tema) {
                let temaBezID = tema.split('-').slice(1).join('-');
                item = perexes.find(p=>toURL(p.name).includes(temaBezID));
                if (!item) item = {name:topics.find(t=>t.id==tema.split('-')[0])?.display_name, desc: ''}
            } else {
                item = perexes.find(p=>p.type.includes('mat'));
            }
            if (!item) navigate("/");
            setActive(item);
        }
    },[perexes,tema])

    useEffect(()=>{
        if (tema&&topics) {
            handleSearchParams();
        } else { setFilters(null); }
    },[searchParams, tema, topics])

    useEffect(()=>{
        setArticles(null);
        setFilters(null);
        handleSearchParams();
        setFiltersOpen(false);
    },[tema])

    useEffect(()=>{
        setArticles(null);

        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            getArticles(); 
        }, 500);
    
        return () => {
            if (timer.current) clearTimeout(timer.current);
        };
    },[filters, search, tema])

    useEffect(()=>{
        document.body.style.overflowY = filtersOpen ? "hidden" :"auto";
        if (document.getElementsByTagName('header')[0]) document.getElementsByTagName('header')[0].style.zIndex = filtersOpen ? 5 :199;
    },[filtersOpen])

    useEffect(() => {
        window.addEventListener("searchbar", handleSearch); 
        return () => window.removeEventListener("searchbar", handleSearch);
    }, []);

    useEffect(()=>{
        if (location.pathname!==(language=='cs'?'/temata':'/topics')) {
          setSearch(undefined);
        }
      },[location])

    const sortFn = (a,b) => {
        if (a.display_name < b.display_name) {
            return -1;
        }
        if (a.display_name > b.display_name) {
            return 1;
        }
            return 0;
    }

    if (!perexes||!active||!articles) {
        return <Loading />
    } else return (
        <main className="w-full py-16 min-h-[70vh]">
            <img src={ridic} className="absolute left-0 bottom-20" />
            <div className="maxSection">
                <div className="text-center pb-10">
                    <h2 className="uppercase">{active.name}</h2>
                    <p>{parse(active.desc||"")}</p>
                </div>
                <div className="flex items-center gap-4 z-[101] pt-4 pb-8 mobil:flex-wrap" >
                    {filtersOpen&&<div onClick={()=>setFiltersOpen(false)} className="fixed left-0 top-0 w-dvw h-dvh bg-black opacity-30"></div>}
                    <div className="tematafiltr absolute -left-4 top-0 bg-blues-200 rounded-xl px-4 py-8 overflow-auto nemobil:pt-12 min-w-56 mobil:z-[2] mobil:h-[calc(100vh-2rem)] mobil:w-[calc(100vw-2rem)] mobil:fixed mobil:left-4 mobil:top-4 transition-all" style={{visibility:filtersOpen?'visible':'hidden',opacity:filtersOpen?1:0}} data-lenis-prevent  >
                        <div className="scrollbox nemobil:max-h-[50vh] overflow-auto">
                            <Collapsible text="Kategorie" clickable open={window.innerWidth<600}>
                                {tema? topics.find(t=>t.id==tema.split('-')[0])?.children.sort(sortFn).map(s=><Checkbox className="py-1" key={s.id} defaultChecked={temp?.filters?.find(f=>f.id==s.id)} name={s.display_name+'~'+s.id} onChange={handleCheckbox}>{s.display_name}</Checkbox>): (
                                    topics.sort(sortFn).map(t=>(
                                        <Collapsible key={t.id} text={<Checkbox name={t.display_name+'~'+t.id} defaultChecked={temp?.filters?.find(f=>f.id==t.id)} onChange={handleCheckbox}>{t.display_name}</Checkbox>}>
                                            {t.children.sort(sortFn).map(s=><Checkbox key={s.id} name={s.display_name+'~'+s.id} defaultChecked={temp?.filters?.find(f=>f.id==s.id)} onChange={handleCheckbox}>{s.display_name}</Checkbox>)}
                                        </Collapsible>
                                    ))
                                )}
                            </Collapsible>
                            <hr />
                            <Collapsible text="Seřadit" clickable>
                                <Checkbox name='orderAsc' checked={temp?.order=='ASC'} onChange={({target})=>{setTemp(prev=>({filters:prev?.filters,order:target.checked?'ASC':null}))}} >Nejnovější</Checkbox>
                                <Checkbox name='orderDesc' checked={temp?.order=='DESC'} onChange={({target})=>{setTemp(prev=>({filters:prev?.filters,order:target.checked?'DESC':null}))}} >Nejstarší</Checkbox>
                            </Collapsible>

                            <button onClick={()=>{setFilters(()=>temp);setFiltersOpen(false)}} className="!mx-auto !my-4">zobrazit položky</button>
                            <a onClick={()=>{setFilters(null);setFiltersOpen(false);setSearch(undefined)}} className="block text-center w-full text-blues-100 underline uppercase cursor-pointer" >vymazat filtr</a>
                    
                        </div>
                        <X className="absolute mobil:fixed top-4 right-4 mobil:top-8 mobil:right-8 text-white text-2xl cursor-pointer" onClick={()=>setFiltersOpen(false)} />
                    </div>
                    <button ref={fltrRef} className="reset text-left bg-blues-200" onClick={()=>{if (!filtersOpen) setFiltersOpen(true);setTemp(filters)}}>Filtrovat a seřadit {filters?.filters?.length>0&&`(${filters.filters.length})`}</button>
                    <div className="flex gap-2 items-center -z-10 mobil:flex-wrap">{filters?.filters?.length<=3&&filters?.filters?.map(f=><span className="text-xs bg-blues-300 py-1 px-2 rounded cursor-pointer" key={f.id} onClick={()=>setFilters(prev=>({order:prev.order,filters:prev.filters.filter(i=>i.id!=f.id)}))}>{f.display_name}&nbsp;&nbsp;&times;</span>)}</div>
                </div>
                {!articles.length&&<div>Nebyly nalezeny články pro zvolené filtry.</div>}
                <div className='articles__row even'>
                    {articles.map(a=><Article key={a.id} data={a} /> )}
                </div>
            </div>
        </main>
    )
}

export default Temata;