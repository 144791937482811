import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Hamburger from './Hamburger';
import "./Header.scss"

import { createContext } from "react";
import { useLenis } from '@studio-freight/react-lenis';

export const HeaderContext = createContext();

const Header = ({ children, className, hamburger, hamburgerLines, ...rest }, ref) => {
  const [open, setOpen] = useState(false);
  const stopScroll = rest.stopScroll||false;
  const headerRef = useRef(); 
  const lenis = useLenis();
  const handleOpen = () => {
    setOpen(!open);
  }

  useEffect(()=>{
    if (stopScroll&&open) {
      document.body.style.overflowY = "hidden";
      lenis.stop();
    } else if (stopScroll) {
      document.body.style.overflowY = "auto";
      if (lenis) lenis.start();
    }
    open ? document.body.classList.add('overlay-open') : document.body.classList.remove('overlay-open')
  }, [open])

  useImperativeHandle(ref, ()=>({el: headerRef.current, open, setOpen}), [headerRef])

//   hamburger: null - neni, jinak napr "400px" - max sirka kde se hamburger zobrazuje  
  return (
    <header ref={headerRef} className={"bg-blues-200 "+className} style={{position: rest.fixed?'fixed':'relative'}}
    >
      <HeaderContext.Provider value={{open, setOpen, stopScroll}}>
      {children}
      {hamburger&&<Hamburger open={open} lineclassName="!bg-white" onClick={(handleOpen)} breakpoint={hamburger} lines={hamburgerLines} />}
      </HeaderContext.Provider >
    </header>
  )
};

export default forwardRef(Header);