import Footer from "../components/Footer"
import Header from "../components/Header/Header"
import Dropdown from "../components/Form/Dropdown"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
import { createContext, useEffect, useRef, useState } from "react"
import { initCookieConsent, setCookiesLanguage } from "../components/UI/Cookies"
import logo from "../assets/svg/logo.svg"
import logo_en from "../assets/svg/logo_en.svg"
import Navigation from "../components/Header/Navigation"
import Overlay from "../components/Header/Overlay"
import { useLenis } from "@studio-freight/react-lenis"
import axios from "axios"
import { APIURL, STORAGEURL, toURL } from ".."
import Collapsible from "../components/UI/Collapsible"
import Input from "../components/Form/Input"
import { MagnifyingGlass } from "@phosphor-icons/react"

export const AppContext = createContext();

const Layout = ({children,language}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const lenis = useLenis();
    const headerRef = useRef();
    const searchRef = useRef();
    const [projects, setProjects] = useState([]);
    const [topics, setTopics] = useState([]);
    const [perexes, setPerexes] = useState(null);
    const [general, setGeneral] = useState(null);

    const [pt, setPt] = useState(0);

    const langArray = [
        {id:1, value: 'cs', label: 'CZ' },
        {id:2, value: 'en', label: 'EN' },
    ]; 
    
    useEffect(() => {
      if (language) {
        setCookiesLanguage(language);
        getTopics();
        getGeneral();
      }
    }, [language]);

    useEffect(() => {
      if (process.env.NODE_ENV === 'production') {
        initCookieConsent();
      }
    }, []);

    const getGeneral = async () => {
      await axios.post(APIURL+'/page', {name: 'general', language}).then(response =>{
        setGeneral(response.data.body);
      })
    }

    const getProjects = async () => {
      await axios.post(APIURL+'/article/filtration/advanced', {type: 'projects', homepage: true, limit: 100, page: 1,language: language}).then(response =>{
        setProjects(response.data.body.articles);
      })
    }

    const getTopics = async () => {
      await axios.post(APIURL+'/categories', {type: 1, language}).then(response =>{
        setTopics(response.data.body);
      	getProjects();
      })
      await axios.post(APIURL+'/collection/items', {type: 'perexes', orderAsc: 'ASC', limit: 100, page: 1,language}).then(response =>{
        setPerexes(response.data.body.items);
      })
    }

    const [hovered, setHovered] = useState(null);

    const [header, setHeader] = useState('');
    useEffect(() => {
      lenis?.on('scroll', (e) => {
        if (e.actualScroll > (window.innerHeight*.75)&&e.direction === 1) {
          setHeader("hidden");
        } else {setHeader("");}
      })
    }, [lenis])

    useEffect(()=>{
      if (headerRef.current?.el) {
        setTimeout(() => {
          setPt(headerRef.current.el.scrollHeight);
          document.body.style.paddingTop = headerRef.current.el.scrollHeight+'px';
        }, 500);
      }
    },[headerRef,language])

    useEffect(()=>{
      // console.log(location);
      if (searchRef.current?.el&&location.pathname!==(language=='cs'?'/temata':'/topics')) {
        searchRef.current.el.value = '';
      }
    },[location,searchRef])

    const closeOverlay = () => {
      if (headerRef.current) {
        headerRef.current.setOpen(false);
      }
    }

    const handleSearch = (search) => {
      closeOverlay();
      navigate('/temata');
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('searchbar', {detail:search}));
      }, 500);
    }

    const HeaderLink = ({children, ...rest}) => <Link onClick={closeOverlay} {...rest} >{children}</Link>

    const SearchBar = (props) => <Input ref={searchRef} name='search' button={<MagnifyingGlass />} placeholder='Hledejte' onButtonClick={handleSearch} onEnter={handleSearch} {...props} />


    return (
        <AppContext.Provider value={{language, projects,topics,perexes,general,pt}}>
          <Header hamburger={language==='cs'?"1050px":"600px"} hamburgerLines={2} fixed ref={headerRef} stopScroll > 
            {general&&<HeaderLink to={language==='cs'?'/':'/en'}><img id='logo' src={STORAGEURL+general.logo_header} alt="PV0 logo" className=" w-[250px] mobil:w-[180px]" /></HeaderLink>}
            <Navigation className="items-center" gap='1rem' moveRight> 
              {language=='cs'&&window.innerWidth>600&&<SearchBar />}
              {language&&<Dropdown name='lang' options={langArray} defaultValue={[langArray.find(l => l.value == language)]} onChange={({ value }) => navigate(value === 'cs' ? '/' : ('/' + value))} />}
            </Navigation>
            <Overlay className="bg-blues-200 shadow-lg overflow-y-auto !min-w-72" data-lenis-prevent >
              {language=='cs'?<>
                <div className="overflow-y-auto">
                  <Collapsible text={<HeaderLink to="/temata">Témata</HeaderLink>}>
                    {topics.map(t=>(
                      <Collapsible key={t.id} text={<HeaderLink className="uppercase" to={(language=='cs'?'/temata/':'/topics/')+t.id+'-'+toURL(t.display_name)} >{t.display_name}</HeaderLink>}>
                          {t.children.map(s=><HeaderLink key={s.id} className="bg-blues-400 text-xs w-fit p-1 rounded" to={(language=='cs'?'/temata/':'/topics/')+t.id+'-'+toURL(t.display_name)+"?t="+toURL(s.display_name)}>{s.display_name}</HeaderLink>)}
                      </Collapsible>
                    ))}
                  </Collapsible>
                </div>
                <hr/>
                <HeaderLink to="/o-nas">O Platformě VIZE 0</HeaderLink>
                <hr/>
                <HeaderLink to="/projekty">Projekty</HeaderLink>
                <hr/> 
                <HeaderLink to="#contacts">Kontakty</HeaderLink>
                <hr/>
                {window.innerWidth<=600&&<SearchBar className="pt-2" />}
              </>:<> 
                <HeaderLink to="/about">About</HeaderLink>
                <hr/>
                <HeaderLink to="/projects">Projects</HeaderLink>
                <hr/>
                <HeaderLink to="#contacts">Contacts</HeaderLink>
                <hr/>
              </>}
            </Overlay>
            {language==='cs'&&window.innerWidth>1050&&<div className={"pruh max-h-24 "+header} onMouseLeave={()=>{setHovered(null)}} >
              <div className="podpruh border-t-2 border-b-2 border-blues-300 my-2">
                {topics.map(t=><Link key={t.id} to={(language=='cs'?'/temata/':'/topics/')+t.id+'-'+toURL(t.display_name)} className={(hovered===t.id||location.pathname.includes(toURL(t.display_name)))?'active':''} onMouseEnter={()=>{setHovered(t.id)}}>{t.display_name}</Link>)}
              </div>
              <div className={"podpruh podpruh2 max-h-14 "+(hovered?'':'hidden')}>
                {hovered&&topics.find(t=>t.id===hovered).children.map(c=><Link to={(language=='cs'?'/temata/':'/topics/')+hovered+'-'+toURL(topics.find(t=>t.id===hovered).display_name)+"?t="+toURL(c.display_name)} key={c.id} >{c.display_name}</Link>)}
              </div>
            </div>}
          </Header>
          {children}
          <Footer />
        </AppContext.Provider>
    )
}

export default Layout;