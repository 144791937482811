import logo_koop from '../assets/svg/logo_koop.svg'
import logo_koop_en from "../assets/svg/logo_koop_en.svg"
import { showCookieConsent } from './UI/Cookies';
import parse from 'html-react-parser';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { APIURL, STORAGEURL, toURL } from '..';
import CustomLink from './CustomLink';
import { AppContext } from '../routes/Layout';
import { useToast } from './UI/useToast';
import { FacebookLogo, InstagramLogo, LinkedinLogo, TwitterLogo, YoutubeLogo } from '@phosphor-icons/react';

const Footer = () => {
    const {addToast} = useToast();
    const {language,topics,general} = useContext(AppContext);
    const [input, setInput] = useState();
    const showConsent = () => {
        showCookieConsent();
    }
    const newsletter  = async (e) => {
        e.preventDefault();
        let val = e.target[0].value;
        if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(val)) {
            await axios.post(APIURL+'/newAnswer',{name:'newsletter',language:'cs',data:[{id:input,value:val}]}).then(resp=>{
                let r = resp.data.body;
                let messages = language==='cs'?{true:'Děkujeme za přihlášení k newslestteru. Vše proběhlo v pořádku.',false:'Něco se nepovedlo. Prosím zkuste to znovu.'}:{true:'Thank you for subscribing to the newsletter. Everything went smoothly.',false:'Something went wrong. Please try again.'};
                addToast({type:r?'success':'error', message:r?messages.true:messages.false, autoclose:5000})
                document.getElementById("newsletter").reset();
            })
        } else {
            addToast({type:'error', message:'Neplatný formát', autoclose:5000})
        }
    }

    const getForm = async () => {
        axios.post(APIURL+'/form',{name:"newsletter",language:'cs'}).then(resp=>{
            resp?.data?.body&&setInput(resp.data.body.inputs[0].id);
        })
    }

    useEffect(()=>{
        getForm();
    },[])

    if (general) return (
        <footer className='mt-auto w-full' id="footer">
            <div className="footer flex gap-8 megadesktop:gap-12 !justify-around flex-row-reverse md:flex-row">
                <div className="footer__left gap-4">
                    <img id='logo' className='min-w-[180px] tablet:min-w-[300px] max-h-20' src={STORAGEURL+general.logo_footer} alt="PV0 logo" />
                    <a className="underline text-center lg:text-left mt-auto" href="mailto:platformavize0@koop.cz">platformavize0@koop.cz</a>
                    <div className='flex flex-row gap-2 justify-center lg:justify-start'>
                        {general.youtube&&<a className="flex items-center gap-2" href={general.youtube} target="_blank"><YoutubeLogo size={32} weight="light"/></a>}
                        {general.facebook&&<a className="flex items-center gap-2" href={general.facebook} target="_blank"><FacebookLogo size={32} weight="light"/></a>}
                        {general.linkedin&&<a className="flex items-center gap-2" href={general.linkedin} target="_blank"><LinkedinLogo size={32} weight="light"/></a> }
                        {general.instagram&&<a className="flex items-center gap-2" href={general.instagram} target="_blank"><InstagramLogo size={32} weight="light"/></a>}
                    </div>
                </div> 
                <div className={'flex flex-row flex-1 items-start flex-wrap gap-8 megadesktop:gap-12 min-w-[40%] footer:flex-col footer:max-h-[450px] '+(language==="cs"?"justify-center":'justify-end tablet:justify-center nemobil:!flex-row')}>
                    {language==='cs'&&<div className='flex flex-col tablet:items-center'>
                        {topics.map((t,i)=><CustomLink key={i} href={(language=='cs'?'/temata/':'/topics/')+t.id+'-'+toURL(t.display_name)} color={"white"}>{t.display_name}</CustomLink>)}
                    </div>}
                    <div className='flex flex-col tablet:items-center' id="contacts">
                        {language==='cs'?<>
                            <CustomLink href="/o-nas" color={"white"}>O Platformě VIZE 0</CustomLink>
                            <CustomLink href="/projekty" color={"white"}>Projekty</CustomLink>
                        </>:<>
                            <CustomLink href="/about" color={"white"}>About</CustomLink>
                            <CustomLink href="/projects" color={"white"}>Projects</CustomLink>
                            
                        </>}
                        <CustomLink onClick={showConsent} color={"white"}>{general.cookies}</CustomLink>
                        {general.download_file&&<CustomLink href={STORAGEURL+general.download_file} color={"white"}>{general.download}</CustomLink>}
                        {general.vyrocnizprava_file&&<CustomLink href={STORAGEURL+general.vyrocnizprava_file} target="_blank" color={"white"}>{general.vyrocnizprava}</CustomLink>}
                    </div>
                    {general.address&&<div className='megadesktop:w-[180px] text-sm tablet:text-center'>
                        {parse(general.address)}
                    </div>}
                </div>
                {language==='cs'&&<div className='newsletter flex flex-col gap-8 tablet:max-w-96 tablet:m-auto'> 
                    <div className='text-left tablet:text-center'>
                        <h4 className='footer:text-lg'>Chcete být stále v obraze? </h4>
                        <p className='footer:text-sm'>Přihlaste se k odběru newsletteru a nic vám neunikne.</p> 
                    </div>
                    <form className="relative" id='newsletter' onSubmit={newsletter}>
                        <input type="email" id="search" className="block w-full p-2 megadesktop:p-4 text-sm text-font-light-base rounded-full bg-gray-50 text-black" placeholder="Váš e-mail" />
                        <button type='submit' className="button  !absolute !right-1 !bottom-1/2 !translate-y-1/2 !font-medium rounded-full !text-xs !py-0 !px-2 !h-[calc(100%-0.5rem)] megadesktop:text-md">odebírat</button>
                    </form>
                </div> }
            </div>
            <div className="copyright">Copyright © {new Date().getFullYear()} Platforma VIZE 0, z. ú.</div> 
        </footer>
    );
}
  
export default Footer 