import parse from 'html-react-parser';
import { useContext } from "react";
import { AppContext } from "../routes/Layout";
import { STORAGEURL, toURL } from '..';
import { Link } from 'react-router-dom';

const Project = ({data}) => { 
    const {language} = useContext(AppContext);  
    const url = (language=='cs'?'/projekt/':'/project/')+data.id_group+'-'+toURL(data.name);
    return (
        <div className="bg-blues-300 rounded-4xl min-w-[200px] flex flex-col gap-2 p-2 w-full flex-grow"> 
            <Link to={url} className='w-full'>
                <img src={STORAGEURL+data?.image} className="w-full rounded-3xl aspect-[3/2] object-cover"/> 
                {data?.logo&&<img src={STORAGEURL+data?.logo} className='absolute right-2 bottom-2 w-1/4 h-auto rounded-xl' />}
            </Link>
            <div className='flex flex-col gap-4 p-2 h-full'>
                <h4 className='text-font-dark-header'><Link to={url}>{data.name}</Link></h4>
                <p className='text-base text-font-dark-primary line-clamp-4'>{parse(data.perex||"")}</p>
                <div className='mt-auto flex justify-end'>
                    <Link className="text-font-dark-link p-2 px-4 group uppercase text-sm text-right" to={url} >
                        {language=='cs'?"Číst více":"More"}
                        <div className="bg-font-dark-link h-[2px] w-0 group-hover:w-full transition-all duration-500"></div>
                    </Link>
                </div>     
            </div>
        </div>
    )
}

export default Project

