import { useContext } from "react";
import { HeaderContext } from "./Header";
const Hamburger = ({ className='', lines=3, lineclassName, onClick }) => {
    const {open} = useContext(HeaderContext);
  return ( 
    <button className={'hamburger '+(open?'open ': '')+className} onClick={onClick}>
        <div className="ham-menu">
            <span className={"line "+lineclassName} style={{transform: open?`rotate(45deg) translate(${lines==3?'7px, 7px':'4px, 4px'})`:'rotate(0) translate(0,0)'}}></span>
            {lines===3&&<span className={"line "+lineclassName}  style={{opacity: open?"0":"1"}}></span>}
            <span className={"line "+lineclassName}  style={{transform: open?`rotate(-45deg) translate(${lines==3?'8px, -8px':'4px, -4px'})`:'rotate(0) translate(0,0)'}}></span>
        </div>
    </button>
  )
};

export default Hamburger;