import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/main.scss';
import { BrowserRouter } from 'react-router-dom';
import ReactLenis from '@studio-freight/react-lenis';
import { ToastProvider } from './components/UI/useToast';
import HashScroll from './components/UI/HashScroll';
import Router from './Router';

const host = window.location.host;
const http = window.location.protocol;
let a = host;
if(host.includes('localhost'))  a = '127.0.0.1:8000';
export const STORAGEURL = host.includes('localhost') ? "https://b2c.agenturagen-preview.cz" : "";
export const APIURL = http+"//"+a+"/api";

export const toURL = (string) => {
  string = string?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return string?.replace(/[^a-z0-9\s]/gi, '').trim().toLowerCase().replace(/\s+/g, '-');
};


const root = ReactDOM.createRoot(document.body);
root.render(
  <BrowserRouter>
    <ReactLenis root>
        <ToastProvider>
          <Router />
      </ToastProvider>
      <HashScroll mode='location' />
    </ReactLenis>
  </BrowserRouter>
);
