import axios from "axios";
import parse from "html-react-parser";
import { useContext, useEffect, useState } from "react";
import { APIURL } from "..";
import { AppContext } from "./Layout";
import Loading from "../components/UI/Loading";
import Project from "../components/Project";
import ridicka from '../assets/svg/ridicka.svg';

const Projekty = () => {
    const {perexes,projects, language} = useContext(AppContext); 

    if (!perexes||!projects) {return <Loading />} else return (
        <>
            <img src={ridicka} className="absolute right-0 bottom-1/2" />
            <main className="maxSection">
                <div className="text-center pt-10">
                    <h2 className="uppercase">{perexes.find(p=>p.type?.includes(language==='cs'?'led projekt':'overview'))?.name}</h2>
                    <p>{parse(perexes.find(p=>p.type?.includes(language==='cs'?'led projekt':'overview'))?.desc||"")}</p>
                </div>
                <div className="py-10 articles__row even">
                    {projects.map(p=>(
                        <Project key={p.id} data={p} />
                    ))}
                </div>
            </main>
        </>
    )
}

export default Projekty;