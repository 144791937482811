import { useLenis } from '@studio-freight/react-lenis';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

let offs = 0-window.innerHeight*0.1;

function HashScroll({mode='window',offset=offs}) { 
    const lenis = useLenis();
    const location = useLocation();
    const [hash, setHash] = useState();
    const lastHash = useRef('');
    const lastPath = useRef(); 
    const [count, setCount] = useState(0);

    useEffect(() => {
      if (mode==='window') {
        function handleHash(){
          setHash(window.location.hash?.slice(1));
        }
        window.addEventListener('hashchange', handleHash);
        return () => {
          window.removeEventListener('hashchange', handleHash);
        };
      }
    },[]);

    useEffect(()=>{
        if (mode==='location'&&location?.hash) {
          if (location.hash&&hash===location.hash?.slice(1)) {
            lastHash.current = hash;
            tryToScroll();
          } else {
            setHash(location.hash?.slice(1));
          }
        }
        if (lastPath?.current && lastPath.current !== location?.pathname) {
          lenis?.scrollTo(0, {immediate: true}); 
        }
        lastPath.current = location.pathname;
    },[location]) 

    useEffect(() => {
      lenis?.scrollTo(0, {immediate:true})
      if (hash&&lenis) {
        lastHash.current = hash;
      }
      tryToScroll();
    }, [hash, lenis]);

    const tryToScroll = () => {
      if (lastHash.current && document.getElementById(lastHash.current)) {
        setTimeout(() => {
          lenis?.scrollTo(document.getElementById(lastHash.current),{offset});
          window.history.replaceState(null, null, ' ');
          lastHash.current = '';
        }, 300);
      } else {
        setCount(currentCount => {
          if (currentCount < 5) {
            setTimeout(tryToScroll, 1000);
            return currentCount + 1;
          }
          return 0;
        });    
      }
    }
  

  return null;
}

export default HashScroll;