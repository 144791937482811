import axios from "axios";
import parse from "html-react-parser";
import { useContext, useEffect, useState } from "react";
import { APIURL, STORAGEURL } from "..";
import { AppContext } from "./Layout";
import Project from "../components/Project";
import { Envelope, Phone } from "@phosphor-icons/react";
import Loading from "../components/UI/Loading";
import { Link } from "react-router-dom";
import znacka from '../assets/svg/znacka.svg';
import auto from '../assets/svg/auto.svg';
import zauto from '../assets/svg/zadekauta.svg';
import kuzel from '../assets/svg/kuzel.svg';

const Onas = () => {
    const {language, projects} = useContext(AppContext);
    const [pagedata, setPagedata] = useState();
    const [features, setFeatures] = useState([]);
    const [team, setTeam] = useState([])
    const [partners, setPartners] = useState([])

    const getPage = async () => {
        await axios.post(APIURL+'/page', {name: 'about', language}).then(response =>{
            setPagedata(response.data.body);
        })
    }
    const getFeatures = async () => {
        await axios.post(APIURL+'/collection/items', {type: 'features', orderAsc: 'ASC', limit: 100, page: 1,language}).then(response =>{
            setFeatures(response.data.body.items);
        })
    }
    const getTeam = async () => {
        await axios.post(APIURL+'/collection/items', {type: 'team', orderAsc: 'ASC', limit: 100, page: 1,language}).then(response =>{
            setTeam(response.data.body.items);
        })
    }
    const getPartners = async () => {
        await axios.post(APIURL+'/collection/items', {type: 'partners', orderAsc: 'ASC', limit: 100, page: 1,language: 'cs'}).then(response =>{
            setPartners(response.data.body.items);
        })
    }

    useEffect(()=>{
        if (language) {
            getPage();
            getFeatures();
            getTeam();
            getPartners();
        }
    },[language])

    if (!pagedata) {return <Loading />} else return (
        <>
        <img src={znacka} className="absolute right-0 top-[35vh]" />
        <main className="py-10 pt-20 w-full m-auto maxSection">
            {/* <img src={auto} className="absolute right-1/2 bottom-1" /> */}
            <div className="bg-white rounded-2xl text-blues-200 flex flex-col items-center py-8 px-4 w-full m-auto">
                <b className="uppercase">{parse(pagedata.intro_subheader||'')}</b>
                <h2 className="uppercase text-center py-4">{parse(pagedata.intro_header||'')}</h2>
                <img src={STORAGEURL+pagedata.intro_banner} alt="O nás" className="rounded-xl" />
                <div className="parsedDiv text-black w-[80%] px-4 !pt-8">{parse(pagedata.intro_text||'')}</div>
            </div>
            <div className="py-24 text-white">
                <h2 className="">{parse(pagedata.projects_header||'')}</h2>
                <div className="parsedDiv">{parse(pagedata.projects_text||'')}</div>
                <div className="w-full flex flex-wrap justify-between gap-y-8">
                    {features.map(f=>(
                        <div key={f.id} className="w-[23%] tablet:w-[48%] mobil:w-full">
                            <img src={STORAGEURL+f.img} alt={f.name} />
                            <h4 className="pt-6 text-blues-100">{f.name}</h4>
                            <div className="parsedDiv italic">{parse(f.desc||'')}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex justify-center md:justify-between gap-4 tablet:flex-wrap">
                {projects.slice(0,3).map(p=>(
                    <Project key={p.id} data={p} />
                ))}
            </div>
            <Link to={language=='cs'?'/projekty':'/projects'} className='button !mx-auto !mt-5'>{pagedata.projects_button_text}</Link>
        </main>
        <main className="bg-blues-300 w-full py-10 !min-h-0">
            <img src={kuzel} className="absolute left-1/2 bottom-1/2" />
            <div className="maxSection">
                <h2>{pagedata.team_header}</h2>
                <p>{parse(pagedata.team_text||'')}</p>
                <div className="py-8 m-auto flex flex-wrap justify-center gap-2">
                    {team.map((t,i)=><>
                        <div key={t.id} className="max-w-[207px] w-[70%] sm:max-w-[250px] sm:w-2/3 lg:max-w-[350px]  lg:w-[32%] aspect-[3/4.5] bg-no-repeat bg-center bg-cover rounded-xl" style={{backgroundImage:`url(${STORAGEURL+t.image})`}}>
                            <div className="absolute bottom-2 left-2 bg-white rounded-xl p-2 lg:p-4" style={{width:'calc(100% - 1rem)'}}>
                                <b className="text-blues-500 text-base md:text-lg lg:text-xl">{parse(t.name||'')}</b>
                                <p className="text-blues-200 leading-tight text-xs md:text-base lg:text-lg">{parse(t.position||'')}</p>
                                {(t.tel||t.email)&&<div className="flex flex-wrap justify-between text-gray-600 text-sm gap-x-4">
                                    {t.email&&<p className="flex items-center gap-2 text-xs md:text-base lg:text-lg"><Envelope /><i className="text-xs md:text-base lg:text-lg">{parse(t.email)}</i></p>}
                                    {t.tel&&<p className="flex items-center gap-2 text-xs md:text-base lg:text-lg"><Phone /><i className="text-xs md:text-base lg:text-lg">{parse(t.tel)}</i></p>}
                                </div>}
                            </div>
                        </div>
                    </>)}
                </div>
            </div>
        </main>
        <main className="w-full py-10 px-4 !min-h-0">
            <img src={zauto} className="absolute top-8 left-0" />
            <div className="maxSection">
                <h2 className="pt-[100px]">{pagedata.partners_header}</h2>
                <p>{parse(pagedata.partners_text||'')}</p>
            </div>
            <div className="bg-white m-auto my-10 max-w-[1600px] flex flex-wrap justify-center gap-8 p-8 rounded-2xl">
                {partners.map(p=>(
                    <a href={p.partners_url} target="_blank" className="flex-grow flex justify-center items-center">
                        <img src={STORAGEURL+p.logo} alt={p.name} className="max-w-[200px] max-h-[180px] mobil:max-w-24" />
                    </a>
                ))}
            </div>
        </main>
        </>
    )
}

export default Onas;