import { useContext, useEffect, useState } from "react";
import axios from 'axios';
import { APIURL } from "..";
import { Link, useNavigate } from "react-router-dom"; 
import Uvod from "../components/Uvod";
import UvodniClanky from "../components/UvodniClanky";
import { AppContext } from './Layout'; 
import Loading from "../components/UI/Loading";
import Project from "../components/Project";
import svg from '../assets/svg/volant.svg'


const HP = () => { 
  const [data, setData] = useState(); 
  const {language,projects} = useContext(AppContext);
  const navigate = useNavigate(); 

  const getPageData = async () => {  
    await axios.post(APIURL+'/page', {name: 'hp', language: language}).then(async response =>{
      setData({...response.data.body, language:language});
      // console.log(response.data.body,"dddd",data)
    }).catch(()=>{
      navigate('/')
    })
  } 

  useEffect(() => {
    if (language) getPageData();
  }, [language]);

  if (!data) {return <Loading />} else return (
    <main>
      <Uvod data={data}/>
      {language==='cs'&&<UvodniClanky data={data}/>}
      <img src={svg} className="absolute bottom-10" style={{left:window.innerWidth>1500?`${(window.innerWidth-1500)/4}px`:"0px"}} />
      {projects.length?<section className="introArticles maxSection py-20 flex flex-col items-center  gap-12">   
          <h2 className='uppercase'>{data?.projects_header}</h2>
          <div className='flex tablet:flex-wrap justify-center gap-4'> 
              {projects.slice(0,3).map((p, k) =>(<Project key={k} data={p} />))}   
          </div>
          <Link className='button' to={language=='cs'?'/projekty':'/projects'}>{data?.projects_button_text}</Link>
      </section>:<Loading />}
    </main>
  );
}

export default HP;
