import { Link, useNavigate } from 'react-router-dom';
import Article from './Article';
import Loading from './UI/Loading';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { APIURL } from '..';
import { AppContext } from '../routes/Layout';
import most from '../assets/svg/most.svg';
import auto from '../assets/svg/auto.svg';
  
const UvodniClanky = ({data}) => {
    const {language} = useContext(AppContext);  
    const [dataArticles, setDataArticles] = useState();  

    const getArticlesData = async () => {    
        await axios.post(APIURL+'/article/filtration/advanced', {type:'articles', language: language, limit:7, page:1, homepage: true}).then(async response =>{
            let items = response.data.body.articles; 
            let groupSizes = [4,3];
            let sizeIndex = 0;
            let arr = [];
            for (let i = 0; i < items.length;) {
                const groupSize = groupSizes[sizeIndex % groupSizes.length];
                const chunk = items.slice(i, i + groupSize);
                arr.push(chunk);
                i += groupSize; 
                sizeIndex++;
            }
            setDataArticles(arr);   
        }) 
    } 

    useEffect(() => { 
        getArticlesData();
    },[]);
    
    return (
        <>
            <img src={most} className='absolute top-[100vh]' style={{left:window.innerWidth>1500?`${(window.innerWidth-1500)/4}px`:"0px"}} />
            <img src={auto} className='absolute bottom-1/3' style={{right:window.innerWidth>1500?`${(window.innerWidth-1500)/4}px`:"0px"}} />
            <section className="introArticles maxSection pt-20 flex flex-col gap-12 items-center">  
                <h2 className='uppercase'>{data?.articles_header}</h2>    
                <div className='articles'>
                    {dataArticles?.map((block, i) =>(
                        <div key={i} className={"articles__row "+(block.length===4?(i%3===0?'odd oddLeft':'odd oddRight'):'even')}>
                            {block.map((a,ix)=>(
                                <Article key={a.id} data={a} 
                                    size={block.length===4?(i%3===0?(ix===0?'large':'mini'):(ix===3?'large':'mini')):''} 
                                />
                            ))}
                        </div>
                    ))||<Loading />}
                </div>
                
                <Link className='button' to={language=='cs'?'/temata':'/topics'} >{data?.articles_button_text}</Link>
            </section>
        </>
    )
}


export default UvodniClanky